<script setup>
import { onMounted, watch, reactive, ref } from "vue";
import useOutboundOrders from "@/hooks/useOutboundOrders";
import { useUserStore } from "@/stores/user";

const props = defineProps({
  outboundOrder: {
    type: Object,
    required: false,
  },
});
const userStore = useUserStore();
const { outboundLoading, fetchOutboundOrderLineItems, lineItems } = useOutboundOrders();
const page = ref(1);
const localState = reactive({
  page: page.value,
  searchText: "",
  filters: [],
  sort: "id",
});
const showAllocated = ref(false);

onMounted(async () => {
  if (props.outboundOrder) {
    fetchOutboundOrderLineItems(props.outboundOrder.id, localState);
  }
});

watch(props.outboundOrder, async () => {
  await fetchOutboundOrderLineItems(props.outboundOrder.id, localState);
});

const updatePage = (newPage) => {
  page.value = newPage;
  localState.page = newPage;
};
</script>

<template>
  <v-card class="mt-4">
    <v-card-title class="d-flex justify-space-between">
      <div>{{ lineItems ? lineItems.total_records : "_" }} Line Item(s)</div>
      <div>
        <v-btn
          v-if="showAllocated"
          color="primary"
          variant="text"
          size="small"
          @click="showAllocated = false"
          >Hide Details</v-btn
        >
        <v-btn
          v-else
          color="primary"
          variant="text"
          size="small"
          @click="showAllocated = true"
          >Show Details</v-btn
        >
      </div>
    </v-card-title>
    <v-table>
      <thead>
        <tr>
          <th class="text-left">ID</th>
          <th class="text-left">SKU/UPC</th>
          <th class="text-left">NAME</th>
          <th class="text-left">QTY</th>
          <th class="text-left">UNITS</th>
          <th class="text-left">ALLOCATED</th>
          <th class="text-left">PICKED</th>
          <th class="text-left">SHIPPED</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!lineItems">
          <td colspan="12" class="text-center py-10">
            <v-progress-circular
              :size="40"
              :width="4"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </td>
        </tr>
        <template
          v-for="item in lineItems.data"
          :key="item.name"
          v-if="!outboundLoading && lineItems"
        >
          <tr>
            <td class="text-no-wrap">
              <a :href="`/products/${item.product_id}`">{{ item.product.number }}</a>
            </td>
            <td class="text-no-wrap">
              {{ item.product.sku }}
              {{ item.product.upc ? `/ ${item.product.upc}` : "" }}
            </td>
            <td class="text-truncate">{{ item.product.name }}</td>
            <td class="text-right">
              {{ item.qty }}
            </td>
            <td>
              {{ item.product.default_unit?.toUpperCase() }}
            </td>
            <td class="text-right">
              {{ item.qty_allocated }}
            </td>
            <td class="text-right">
              {{ item.qty_picked }}
            </td>
            <td class="text-right">
              {{ item.qty_shipped }}
            </td>
          </tr>

          <template v-for="invItem in item.allocated_inventory">
            <template v-if="invItem.status_id == 9">
              <tr :class="{ hide: !showAllocated }" class="small">
                <td class="text-right">
                  <em style="font-size: 75%">
                    {{ item.product.kit ? "COMPONENT" : "" }}
                  </em>
                </td>
                <td>&nbsp;&nbsp;&rarr;&nbsp;To Pick</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td class="text-right">{{ invItem.qty }}</td>
                <td class="text-right">
                  {{ item.picked.reduce((sum, i) => sum + i.qty, 0) }}
                </td>
                <td>&nbsp;</td>
              </tr>
              <template v-for="pickedLp in item.picked">
                <tr :class="{ hide: !showAllocated }" class="small">
                  <td></td>
                  <td>&nbsp;&nbsp;&rarr;&nbsp;Pending</td>
                  <td style="padding-left: 15px">
                    <strong>LPN:</strong> {{ pickedLp.number }} ({{ invItem.lp_id }})
                    &bull; <strong>LOT:</strong> {{ pickedLp.lot || "-" }} &bull;
                    <strong>EXP:</strong> {{ pickedLp.expiry || "-" }} &bull;
                    <strong>QTY:</strong> {{ pickedLp.qty || "-" }}
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-right">{{ "-" }}</td>
                  <td class="text-right">{{ pickedLp.qty }}</td>
                  <td></td>
                </tr>
              </template>
            </template>

            <tr class="small" :class="{ hide: !showAllocated }" v-else>
              <td class="text-right">
                <em style="font-size: 75%">
                  {{ item.product.kit ? "COMPONENT" : "" }}
                </em>
              </td>
              <td>
                &nbsp;&nbsp;&rarr;
                <span v-if="invItem.status_id != 3">Staged</span>
                <span v-else>Shipped</span>
              </td>
              <td style="padding-left: 15px">
                <div v-for="lp in invItem.lps">
                  <strong>LPN:</strong>
                  <a
                    v-if="invItem.lp_id"
                    :href="`/lps/${invItem.lp_id}`"
                    v-html="lp.number"
                  ></a>
                  <span v-else v-html="lp.number"></span>
                  &bull; <strong>LOT:</strong> {{ lp.lot || "-" }} &bull;
                  <strong>EXP:</strong> {{ lp.expiry || "-" }} &bull;
                  <strong>QTY:</strong> {{ lp.qty || "-" }}

                  <!-- liquid death -->
                  &bull;
                  <span
                    v-if="
                      userStore.user.permissions.hot_swap_lp &&
                      item.product.client_id == '10006'
                    "
                    class="text-red"
                    style="cursor: pointer"
                  >
                    <small>Remove</small>
                  </span>
                </div>
              </td>
              <td></td>
              <td></td>
              <td class="text-right">{{ invItem.qty }}</td>
              <td class="text-right">{{ invItem.qty_picked }}</td>
              <td></td>
            </tr>
          </template>
        </template>
      </tbody>
    </v-table>
    <v-pagination
      v-model="page"
      :length="lineItems.total_pages"
      @update:modelValue="updatePage"
      v-if="lineItems && lineItems.total_pages > 0"
    ></v-pagination>
  </v-card>

  <!-- Check if liquid death -->
  <v-card
    class="mt-4"
    v-if="userStore.user.permissions.hot_swap_lp && item.product.client_id == '10006'"
  >
    <v-card-title> Admin Tools </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <div>
            <label
              ><strong>Add LP ID</strong>
              <br />
              <small
                >Use this tool to add an LP to this order. You need to submit the
                <strong>LP ID</strong> not the LPN.</small
              >
            </label>
            <v-text-field
              class="my-4"
              variant="outlined"
              density="compact"
              placeholder="Add LP ID"
              hideDetails
            />
          </div>
          <v-btn color="primary" size="small">Add</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.hide {
  display: none;
}

.small {
  font-size: 80%;
  font-weight: 400;
}

table td,
table th {
  padding: 0px 10px !important;
}
</style>
