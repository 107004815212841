<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import OrderOtherDetails from "../../../components/order/OrderOtherDetails.vue";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import useOutboundOrders from "@/hooks/useOutboundOrders";
import OutboundOrderLayout from "@/layouts/OutboundOrderLayout.vue";
import OutboundDestinationDetails from "@/components/order/OutboundDestinationDetails.vue";
import OutboundShipperDetails from "@/components/order/OutboundShipperDetails.vue";
import OutboundLineItems from "@/components/order/OutboundLineItems.vue";

const { outboundLoading, outboundOrder, fetchOutboundOrder } = useOutboundOrders();
const route = useRoute();

onMounted(async () => {
  await fetchOutboundOrder(route.params.id).then(() => {
    setPageTitle(`Outbound Order #OUT-${outboundOrder.value.id}`);
  });
});
</script>

<template>
  <OutboundOrderLayout :outboundOrder="outboundOrder">
    <div class="w-100">
      <div v-if="!outboundLoading && outboundOrder" class="px-4">
        <div class="w-100">
          <div class="w-auto px-2">
            <OrderHeader
              orderType="outbound"
              :orderId="outboundOrder.id"
              :status="outboundOrder.order_status.short_label"
            />
          </div>

          <v-card class="mt-2 pa-2 w-auto" color="transparent" elevation="0">
            <div class="d-flex">
              <OutboundShipperDetails :outboundOrder="outboundOrder" />
              <OutboundDestinationDetails :outboundOrder="outboundOrder" />
              <OrderOtherDetails :inboundOrder="outboundOrder" />
            </div>
            <OutboundLineItems :outboundOrder="outboundOrder" />
          </v-card>
        </div>
      </div>
    </div>
  </OutboundOrderLayout>
</template>
